<template>
  <div class="container">
    <el-row>
      <el-col :span="24">
        <el-card type="border-card">
          <el-form ref="form-input" label-width="80px">
            <el-form-item label="选择车型">
              <el-select
                v-model="car_id"
                placeholder="选择车型"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, k) in carList"
                  :key="k"
                  :label="item.name"
                  :value="k"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="写作说明">
              <el-input type="textarea" v-model="prompt"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
              style="width:100%"
              type="primary"
              class="submit-button"
              @click="write"
              :loading="writing"
              >{{ sub_button_text }}</el-button
            >
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <el-col :span="24">
        <el-card>
          <div class="result-area" v-html="result_text"></div>
        </el-card>
      </el-col>
      <el-col :span="24">
        <img src="@/assets/imgs/wmp-ercode.png" class="qr_code" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import { mapActions, mapMutations, mapState } from "vuex";
import {result} from "@/data_mock/test.js";
import { getToken } from "@/utils/auth";
import { formatString } from "@/utils/libs"
import { mapActions, mapState } from "vuex";

export default {
  name: "common",
  data() {
    return {
      prompt: "从动力,驾驶体验,性价比,车辆外观等方向撰写推荐图文",
      car_id :0,
      request_id: "",
      word_size: 1000,
      writing: false,
      status_code: null,
      result_text: "",
      result_buffer:"",        //回调检查队列
      result_buffer_open:false //是否打开队列
    };
  },
  computed: {
    ...mapState("cardata", ["carList",'prompt_tmplate']),
    ...mapState("completion", ["instance"]),
    sub_button_text() {
      return this.status_code == 206 ? "继续写作" : "立即生成";
    },
    formatedPrompt(){//格式化之后的命令
      const carObj = this.carList[this.car_id]
      let tmplate = this.prompt_tmplate
      let image_table = ["id,prompt"]
      // carObj.images
      carObj['images'].forEach((v,i) => {
        image_table.push(`${v['id']},${v['prompt']}`)
      });
      return formatString(tmplate,{
        "name":carObj.name,
        "params":carObj.params,
        "user_prompt":this.prompt,
        "images":image_table.join("\n")
      })
    },
    completionRequestData() {

      let prompt =
        this.status_code == 206 ? this.formatedPrompt + this.result_text : this.formatedPrompt;
      return {
        //创建请求数据
        max_tokens: Math.ceil(this.word_size * 3.5),
        prompt: prompt,
        user_token: getToken(),
      };
    },
  },
  methods: {
    ...mapActions("completion", ["completion"]),
    clear() {
      this.result_text = "";
      this.request_id = "";
      this.writing = false;
      this.status_code = "";
      this.result_buffer="";        //回调检查队列
      this.result_buffer_open=false //是否打开队列
    },
    test_write(p){
      p = p || 0
      if(p<result.length){
        console.log(p)
        this.onmessage(result[p])
        console.log(this.result_buffer)
        setTimeout(() => {
          this.test_write(++p)
        }, 30);
      }
      
    },
    write() {
      console.log('write')
      if(this.status_code !=206 ){
        this.clear()
      }
      this.writing = true;
      let handle = this.completion({
        data: this.completionRequestData,
        onmessage: this.onmessage,
      });
      handle
        .then(({ code, data }) => {
          this.writing = false;
          this.status_code = code;
          console.log(code, data);
          if (code == 200) {
            this.$message.success("写作完成，记得保存呦！！");
          } else if (code == 50010) {
            this.$router.push({ name: "Recharge" });
          } else {
            this.$message.info("还有内容，点击继续写作，获取后续内容！");
          }
        })
        .catch((err) => {
          console.log(err);
          this.writing = false;
        });
    },
    onmessage(resp) {//处理标签
      
      if(resp==="["){
        this.result_buffer_open = true;
      }
      if(this.result_buffer_open){//如果已经打开了就添加到buffer
        this.result_buffer+=resp;
      }
      if(resp==="]"||this.result_buffer.length>30){
        this.result_buffer+=resp;
        this.result_buffer_open = false;
        let imgId = this.result_buffer.match(/\[img\s+id='(\d+)'\]/)[1];
        if(imgId){//加查到了
          let carObj = this.carList[this.car_id]
          let image = carObj['images'].filter((v)=>{return v.id==imgId})
          if(image.length>0){
            this.result_buffer = `<img src="${image[0]['src']}" style="width:100%"/>`
          }else{
            this.result_buffer = ""
            return 0;
          }
        }
      }
      if(!this.result_buffer_open&&this.result_buffer.length>0){
        this.result_text += this.result_buffer;
        this.result_buffer = ""
        this.result_buffer_open=false
        return 0
      }
      if(!this.result_buffer_open){
        this.result_text+=resp
      }
      
    },
    copyResult() {
      this.$copyText(this.result_text)
        .then((e) => {
          this.$message({
            type: "success",
            message: "复制成功",
          });
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: "浏览器不支持复制，请手动复制",
          });
        });
    },
    getInstance() {
      //获取列子
      let cmd = this.instance.shift();
      this.clear();
      this.prompt = cmd;
      this.instance.push(cmd);
    },
    seeToken() {
      this.result_text = getToken();
    },
  },
  mounted() {//写一下测试


  },
};
</script>

<style lang="stylus" scoped>
.container {
  display: block;
  box-sizing: border-box;
  width: 100%;
  .result-area{
    display: block;
    width :100;
    .img{
      width:100%
    }
  }
  .qr_code {
    width: 100%;
    margin-top: 15px;
  }

  .input-wrapper {
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 24px;
    margin-bottom: 20px;

    .submit-button {
      margin-top: 20px;
      background-image: linear-gradient(90deg, #4a98ff, #2659ff);
      float: right;
    }

    .word_size {
      display: inline-block;
      margin-top: 25px;
    }

    .title {
      font-size: 18px;
      font-weight: 500;

      .desc {
        display: inline-block;
        margin-left: 10px;
        color: #8b8c95;
        font-size: 14px;
        font-weight: 400;
        vertical-align: bottom;
      }

      .instance {
        display: inline-block;
        float: right;
        color: #2932e1;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        vertical-align: top;
      }
    }
  }

  .output-wrapper {
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 24px;

    .title {
      font-size: 18px;
      font-weight: 500;

      .copy {
        display: inline-block;
        float: right;
        color: #2932e1;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        vertical-align: top;
      }
    }
  }
}
</style>